import styles from './style.module.scss'
import cx from 'classnames'
import { Button } from '../Button'

const Thumbnail = ({ className, onClose, src = '' }) => {
    return (
        <div className={cx(styles.wrapper, 'inset-md')}>
            {src.endsWith('pdf') ? (
                <iframe
                    src={`https://docs.google.com/gview?url=${src}&embedded=true`}
                    className={cx(className, styles.thumbnail)}
                    frameBorder="0"
                />
            ) : (
                <img src={src} className={cx(className, styles.thumbnail)} />
            )}

            {onClose && (
                <div className={styles.closeBtn}>
                    <Button onClick={onClose} variant="secondaryText">
                        <ion-icon
                            name="close-outline"
                            style={{ fontSize: '18px' }}
                        ></ion-icon>
                    </Button>
                </div>
            )}
        </div>
    )
}

export { Thumbnail }
