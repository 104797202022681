import { FC, ReactNode } from 'react'

import { Tooltip } from './Tooltip'

type Props = {
    children: ReactNode
    inline?: boolean
}

const trigger = <ion-icon name="help-circle-outline" />

export const InfoIcon: FC<Props> = ({ children, inline }) => {
    if (inline) {
        return (
            <div className="inline">
                <Tooltip trigger={trigger}>{children}</Tooltip>
            </div>
        )
    }

    return <Tooltip trigger={trigger}>{children}</Tooltip>
}
