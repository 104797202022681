import { FC, ReactNode } from 'react'
import cx from 'classnames'

import styles from './style.module.scss'

type Props = {
    title: string
    children: ReactNode
    right?: boolean
    titleClassName?: string
    className?: string
}

export const KeyValue: FC<Props> = ({
    title,
    children,
    right,
    titleClassName = 'neutral-600',
    className,
}) => {
    return (
        <div className={cx('d-flex flex-column flex-md-row stacked-xs', className)}>
            <div className={cx(styles.label, { ['flex-fill']: right })}>
                <span className={titleClassName}>{title}</span>
            </div>
            <div className={cx({ ['flex-fill']: !right }, 'pre-wrap')}>{children}</div>
        </div>
    )
}
