import { useState } from 'react'
import styles from './style.module.scss'
import cx from 'classnames'
import { Thumbnail } from 'ds/components/Thumbnail'
import { Spinner } from 'ds/components/Spinner'
import { Dropzone } from 'ds/components/Dropzone'
import { uploadFileToS3 } from 'lib/utils/uploadFileToS3'
import { Label } from '../Label'

const FileInput = ({ className, label, value, onChange, s3Directory }) => {
    // RETURNS THE UPLOADED URL
    // should return the actual file; should use a wrapper to upload file and then return url.
    // ONE FILE ONLY, for multiple, use FileInputMultiple
    const [loading, setLoading] = useState(false)

    const handleUpload = async (acceptedFiles) => {
        const file = acceptedFiles[0]
        setLoading(true)
        const fileUrl = await uploadFileToS3(file, s3Directory)
        setLoading(false)
        if (!fileUrl) {
            console.error(`Algo salió mal uploading ${file.name}`)
            return
        }
        onChange(fileUrl)
    }

    return (
        <div className={className}>
            <div className="stacked-md">
                <Label>{label}</Label>
                <Dropzone onDrop={handleUpload} />
            </div>
            <div className="stacked-lg">
                {!loading && value && (
                    <div key={value} className={cx('inline-md', 'center-text')}>
                        <Thumbnail
                            key={value}
                            src={value}
                            className="stacked-md"
                            onClose={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onChange('')
                            }}
                        />
                    </div>
                )}

                {loading && (
                    <div className={cx('inline', 'inset-md', styles.loading)}>
                        <div className="stacked-md">Guardando...</div>
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    )
}

export { FileInput }
