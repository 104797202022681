import { isEmpty } from 'lodash'
import { stringify } from 'qs'

// Set environment variables using NEXT_PUBLIC_
// to have them accessible in the browser
// See https://nextjs.org/docs/basic-features/environment-variables

const apiTarget = process.env.NEXT_PUBLIC_API_TARGET

type Params = {
    url: string
    queryParams?: Record<string, any>
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
    body?: any
}

type PortalParams = { portalRequest?: boolean; portalToken?: string; portalId?: string }

type PayParams = { payRequest?: boolean; paySubscriptionId?: string }

async function makeRequest(params: Params & PortalParams & PayParams): Promise<Response> {
    const headers = {}
    const body = params.body ? JSON.stringify(params.body) : undefined
    const method = params.method ?? 'GET'

    if (body) {
        headers['Content-Type'] = 'application/json'
    }
    let token
    if (params.portalToken) {
        headers['Authorization'] = `Bearer ${params.portalToken}`
    } else if (params.portalId) {
        headers['X-Portal-Id'] = params.portalId
    } else if (params.paySubscriptionId) {
        headers['X-Pay-Subscription-Id'] = params.paySubscriptionId
    } else if (localStorage && !params.portalRequest) {
        token = localStorage.getItem('token')
        if (token) headers['Authorization'] = `Token ${token}`
    }

    const url = getApiUrl(params.url, params.queryParams)

    return fetch(url, {
        method,
        headers,
        body,
        // credentials: params.portalRequest ? 'include' : undefined,
    })
}

const getApiUrl = (path: string, query: Record<string, any> = {}): string => {
    const url = path.includes('http') ? path : `${apiTarget}/api${path}`
    return isEmpty(query) ? url : `${url}?${stringify(query)}`
}

export async function makeApiRequest<T = any>(
    params: Params
): Promise<T & { error?: string; message?: string }> {
    const response = await makeRequest(params)

    return response.json()
}

const getPortalId = () => {
    // Path should be /portal/{{portal_id}}/*
    const path = window.location.pathname.split('/')
    if (path[1] !== 'portal' || !path[2]) {
        throw new Error('No se puede hacer una petición de portal fuera del portal')
    }

    return path[2]
}

// Later refactor when we incorporate invoice_public_id for one time payments
const getPaySubscriptionId = () => {
    // Path should be /pay/subscription/{{subscription_public_id}}
    const path = window.location.pathname.split('/')
    // Check if path starts with /pay
    if (path[1] !== 'pay') {
        throw new Error('La ruta debe comenzar con /pay')
    } else if (path[2] !== 'subscription' || !path[3]) {
        throw new Error('La petición de pay no es válida')
    }

    return path[3]
}

export async function makePortalApiRequest<T = any>(
    params: Params & PortalParams
): Promise<T & { error?: string; message?: string }> {
    const portalId = getPortalId()

    const response = await makeRequest({ ...params, portalRequest: true, portalId })

    return response.json()
}

export async function makePayApiRequest<T = any>(
    params: Params & PayParams
): Promise<T & { error?: string; message?: string }> {
    const paySubscriptionId = getPaySubscriptionId()

    const response = await makeRequest({ ...params, payRequest: true, paySubscriptionId })

    return response.json()
}

export async function downloadPortalFile(
    params: Params & PortalParams & { filename: string }
): Promise<any> {
    const response = await makeRequest({
        ...params,
        portalRequest: true,
        portalId: getPortalId(),
    })

    await downloadResponse(response, { ...params, throwError: true })
}

export async function downloadFile(
    params: Params & { filename: string; throwError?: boolean }
): Promise<any> {
    const response = await makeRequest(params)

    await downloadResponse(response, params)
}

const downloadResponse = async (
    response: Response,
    params: { filename: string; throwError?: boolean }
) => {
    if (params.throwError && !response.ok) throw new Error('Error descargando archivo')
    const blob = await response.blob()

    const link = document.createElement('a')
    const objectUrl = URL.createObjectURL(blob)
    link.download = params.filename
    link.href = objectUrl
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(objectUrl)
}
